import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import SettingsAccessibilityIcon from "@mui/icons-material/SettingsAccessibility";
import MapIcon from "@mui/icons-material/Map";
import CategoryIcon from "@mui/icons-material/Category";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import PaymentsIcon from "@mui/icons-material/Payments";
import GroupIcon from "@mui/icons-material/Group";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import DiscountIcon from "@mui/icons-material/Discount";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import GarageIcon from "@mui/icons-material/Garage";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import StayPrimaryPortraitIcon from "@mui/icons-material/StayPrimaryPortrait";
import TranslateIcon from "@mui/icons-material/Translate";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import FeedbackIcon from "@mui/icons-material/Feedback";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
// import CarCrashIcon from "@mui/icons-material/CarCrash";
import { SvgIconComponent } from "@mui/icons-material";
import { PathComparator } from "components/NavSidebar/components/ListItem";
import { Subjects } from "../context/AbilityContext";

export enum Paths {
  Root = "/",
  Login = "/login",
  ObjectsManagment = "/objects-managment",
  Objects = "/objects",
  Categories = "/categories",
  Zones = "/zones",
  Parking = "/parkings",
  Parkomats = "/parkomats",
  Terminals = "/terminals",
  Scoreboards = "/scoreboards",
  Accounts = "/accounts",
  Roles = "/roles",
  AccountsList = "/list",
  Tariffs = "/tariffs",
  CalendarYears = "/holidays",
  Benefits = "/benefits",
  BenefitsActive = "/active",
  Grants = "/grants",
  Notifications = "/notifications",
  Push = "/push",
  Abonements = "/abonements",
  AbonementsActive = "/active",
  Monitoring = "/monitoring",
  Sms = "/sms",
  Sessions = "/sessions",
  SystemSettings = "/system-settings",
  Map = "/map",
  Mobile = "/mobile",
  AvailableLanguages = "/available-languages",
  Vehicles = "/vehicles",
  Currency = "/currency",
  Permissions = "/permissions",
  PermissionsActive = "/active",
  ParkingSessionsLog = "/parkings/sessions",
  LegalEntityBalance = "/legal-entity-balance",
  FeedbackTopicsList = "/feedback/topics/list",
  FeedbackTopicsForm = "/feedback/topics/form",
  Reports = "/reports",
  Complexes = "/complexes",
  IllegalSessions = "/illegal-sessions"
}

export type NavItem = {
  title: string;
  path?: Paths;
  pathStrict?: boolean;
  Icon?: SvgIconComponent;
  children?: NavItem[];
  pathComparator?: PathComparator;
  abilitySubjectName: Subjects;
};

export const sideMenuItems: NavItem[] = [
  {
    title: "navigation.objects_management.label",
    path: Paths.ObjectsManagment,
    Icon: MapIcon,
    abilitySubjectName: "Parking",
    children: [
      {
        title: "navigation.objects_management.categories",
        path: Paths.Categories,
        Icon: CategoryIcon,
        abilitySubjectName: "Category"
      },
      {
        title: "navigation.objects_management.objects",
        path: `${Paths.Objects}${Paths.Zones}` as Paths,
        Icon: EditLocationIcon,
        abilitySubjectName: "Zone",
        pathComparator: (_, currentPath) =>
          (currentPath ?? "").includes(`${Paths.ObjectsManagment}${Paths.Objects}`)
      }
    ]
  },
  {
    title: "navigation.users.label",
    path: Paths.Accounts,
    Icon: AccountBoxIcon,
    abilitySubjectName: "User",
    children: [
      {
        title: "navigation.users.list",
        path: Paths.AccountsList,
        Icon: GroupIcon,
        abilitySubjectName: "User"
      },
      {
        title: "navigation.users.roles",
        path: Paths.Roles,
        Icon: SettingsAccessibilityIcon,
        abilitySubjectName: "Role"
      }
    ]
  },
  {
    title: "navigation.tariffs",
    path: Paths.Tariffs,
    Icon: PaymentsIcon,
    abilitySubjectName: "Tariff"
  },
  {
    title: "navigation.holidays_calendar",
    path: Paths.CalendarYears,
    Icon: CalendarMonthIcon,
    abilitySubjectName: "Holiday"
  },
  {
    title: "navigation.parking_sessions",
    path: `${Paths.Parking}${Paths.Sessions}` as Paths,
    Icon: GarageIcon,
    abilitySubjectName: "ParkingSessionsLog"
  },
  {
    title: "navigation.benefits.label",
    path: Paths.Benefits,
    Icon: DiscountIcon,
    abilitySubjectName: "Benefit",
    children: [
      {
        title: "navigation.benefits.active",
        path: Paths.BenefitsActive,
        Icon: LocalOfferIcon,
        abilitySubjectName: "Benefit"
      },
      {
        title: "navigation.benefits.grants",
        path: Paths.Grants,
        Icon: LoyaltyIcon,
        abilitySubjectName: "BenefitGrant"
      }
    ]
  },
  {
    title: "navigation.abonements.label",
    path: Paths.Abonements,
    Icon: BookmarksIcon,
    abilitySubjectName: "Abonement",
    children: [
      {
        title: "navigation.abonements.active",
        path: Paths.AbonementsActive,
        Icon: BookmarkAddedIcon,
        abilitySubjectName: "Abonement"
      },
      {
        title: "navigation.abonements.grants",
        path: Paths.Grants,
        Icon: BookmarkAddIcon,
        abilitySubjectName: "AbonementGrant"
      }
    ]
  },
  {
    title: "navigation.permissions.label",
    path: Paths.Permissions,
    Icon: LockIcon,
    abilitySubjectName: "Permission",
    children: [
      {
        title: "navigation.permissions.active",
        path: Paths.PermissionsActive,
        Icon: VpnKeyIcon,
        abilitySubjectName: "Permission"
      },
      {
        title: "navigation.permissions.grants",
        path: Paths.Grants,
        Icon: LockOpenIcon,
        abilitySubjectName: "PermissionGrant"
      }
    ]
  },
  // TODO: uncomment once backend is ready
  // {
  //   title: "Исходящие СМС",
  //   path: `${Paths.Monitoring}${Paths.Sms}` as Paths,
  //   Icon: SmsIcon,
  //   abilitySubjectName: "Unknown"
  // },
  // {
  //   title: "Уведомления",
  //   path: Paths.Notifications,
  //   Icon: NotificationsIcon,
  //   abilitySubjectName: "Unknown",
  //   children: [
  //     {
  //       title: "Push-уведомления",
  //       path: Paths.Push,
  //       Icon: SmartphoneIcon,
  //       abilitySubjectName: "Unknown"
  //     }
  //   ]
  // },
  {
    title: "navigation.system_settings.label",
    path: Paths.SystemSettings,
    Icon: SettingsApplicationsIcon,
    abilitySubjectName: "SystemSettings",
    children: [
      {
        title: "navigation.system_settings.map",
        path: Paths.Map,
        Icon: MapIcon,
        abilitySubjectName: "SystemSettings"
      },
      {
        title: "navigation.system_settings.mobile_app",
        path: Paths.Mobile,
        Icon: StayPrimaryPortraitIcon,
        abilitySubjectName: "SystemSettings"
      },
      {
        title: "navigation.system_settings.available_langs",
        path: Paths.AvailableLanguages,
        Icon: TranslateIcon,
        abilitySubjectName: "SystemSettings"
      },
      {
        title: "navigation.system_settings.vehicles",
        path: Paths.Vehicles,
        Icon: DirectionsCarIcon,
        abilitySubjectName: "SystemSettings"
      },
      {
        title: "navigation.system_settings.currency",
        path: Paths.Currency,
        Icon: MonetizationOnIcon,
        abilitySubjectName: "SystemSettings"
      }
    ]
  },
  {
    title: "navigation.legal_entity_balance",
    path: Paths.LegalEntityBalance,
    Icon: AccountBalanceWalletIcon,
    abilitySubjectName: "LegalEntityBalance"
  },
  {
    title: "navigation.feedback",
    path: Paths.FeedbackTopicsList,
    Icon: FeedbackIcon,
    abilitySubjectName: "Feedback"
  },
  {
    title: "navigation.reports",
    path: Paths.Reports,
    Icon: InsertChartIcon,
    abilitySubjectName: "Reports"
  },
  {
    title: "navigation.complexes",
    path: Paths.Complexes,
    Icon: CameraAltIcon,
    abilitySubjectName: "Complexes"
  }
  // {
  //   title: "navigation.illegal_sessions",
  //   path: Paths.IllegalSessions,
  //   Icon: CarCrashIcon,
  //   abilitySubjectName: "IllegalSessions"
  // }
];
